import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { Dialog } from '../../components/base';
import { ConsultCardA, ConsultCardB, ConsultSelectA } from '../../components/consult';
import './index.scss';
import { ReactComponent as CloseIcon } from '../../static/icons/close.svg';

import { CounselingField, requestState } from '../../store/RequestStore';
import { useLocalStorage } from '../../shared/utill';

const Consult = () => {
  const [request, setRequest] = useRecoilState(requestState);
  const resetRequest = useResetRecoilState(requestState);
  const [persistedRequest, setPersistedRequest] = useLocalStorage('request', request);

  const [popupA, setPopupA] = useState(false);
  const [popupB, setPopupB] = useState(false);
  const [active, setActive] = useState('A');
  const [detailDialog, setDetailDialog] = useState(false);
  const location = useLocation();

  useEffect(() => resetRequest());
  
  const isMobile = useMediaQuery({
    query: '(max-width: 1200px)'
  });

  const showPopupA = (e: any) => {
    e.preventDefault();
    setPopupA(true);
  };

  const showPopupB = (e: any) => {
    e.preventDefault();
    setPopupB(true);
  };

  const switchActive = (e: any) => {
    e.preventDefault();
    setActive(e.target.id);
  };

  const selectOption = (counselingType: CounselingField) => {
    setRequest({ ...request, counselingType: counselingType });
    setPersistedRequest(request);
  };

  return (
    <div className="consult consult-1">
      <Link className="back-button" to="/">
        <CloseIcon />
      </Link>
      <div className="consult-content">
        <div className="content">
          <h1 className="title">원하시는 상담 분야를 선택해주세요</h1>
          <ul className="card-wrapper">
            <Link
              to={`/consult2${location.search}`}
              onClick={() => selectOption(CounselingField.DEFAULT)}
            >
              <ConsultSelectA title="비상주 사무실" tag="최저가" />
            </Link>
            <Link
              to={`/consult2${location.search}`}
              onClick={() => selectOption(CounselingField.A)}
            >
              <ConsultSelectA
                title="결합 A형"
                desc="(비상주 사무실+세무서비스)"
                tag="연 12만원 혜택"
                onClick={showPopupA}
              />
            </Link>
            <Link
              to={`/consult2${location.search}`}
              onClick={() => selectOption(CounselingField.B)}
            >
              <ConsultSelectA
                title="결합 B형"
                desc="(비상주 사무실 + 세무서비스 + 무료법인설립)"
                tag="연 68만원 혜택"
                onClick={showPopupB}
              />
            </Link>
          </ul>
          {isMobile ? (
            detailDialog ? (
              <div className="card-container">
                <div className="card-content">
                  <img
                    className="close-button"
                    src={require('../../static/close-button.png')}
                    onClick={() => setDetailDialog(false)}
                  />
                  <div className="switch-wrapper">
                    <div
                      className={`switch ${active === 'A' ? 'active' : ''}`}
                      id="A"
                      onClick={switchActive}
                    >
                      결합 A형
                    </div>
                    <div
                      className={`switch ${active === 'B' ? 'active' : ''}`}
                      id="B"
                      onClick={switchActive}
                    >
                      결합 B형
                    </div>
                  </div>
                  {active === 'A' ? <ConsultCardA /> : <ConsultCardB />}
                </div>
              </div>
            ) : false
          ) : (
            <>
              <Dialog visible={popupA} onClose={() => setPopupA(false)}>
                <ConsultCardA onClose={() => setPopupA(false)} />
              </Dialog>
              <Dialog visible={popupB} onClose={() => setPopupB(false)}>
                <ConsultCardB onClose={() => setPopupB(false)} />
              </Dialog>
            </>
          )}
        </div>
        <a className="detail" onClick={() => setDetailDialog(true)}>
          자세히 알아보기
        </a>
      </div>
    </div>
  );
};

export default Consult;
